import React, { Component } from 'react';
import logo from '../Assets/img/logo.svg';

class Header extends Component {

  render() {
    const styles = {
      header: {
        margin: `0`
      },
      logo: {
        width: `200px`,
        height: `auto`,
        padding: `0 0 0 45px`
      },
      button: {
        display: `block`,
        margin: `0 45px`,
        padding: `15px 25px`,
        border: `2px solid #f9be00`,
        fontSize: `18px`,
        fontWeight: `bold`,
        textTransform: `uppercase`,
        textDecoration: `none`,
        textAlign: `center`,
        color: `#f9be00`,
        transition: `all 250ms ease-in-out`
      }
    }
    return (
      <header style={styles.header}>
        <div className="row">
          <a href="https://markrent.com/" title="Mark Brower Website">
            <img src={logo} alt="Mark Brower Properties" style={styles.logo} />
          </a>
          <a href="tel:+14803362556" title="Call Mark Brower" style={styles.button}>
            480-336-2556
          </a>
        </div>
      </header>
    );
  }

}

export default Header;
