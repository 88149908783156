import React, { Component } from 'react';
import fetchGetStatus from 'fetch-get-status';
import { BrowserRouter as Router, Route } from "react-router-dom";

import Step1 from './Components/Step1';
import Step2 from './Components/Step2';
import Step3 from './Components/Step3';
import AddressError from './Components/AddressError';
import DatabaseError from './Components/DatabaseError';
import calculationValues from './calculationValues.json';
import Header from './Components/Header';
import Intro from './Components/Intro';
import Footer from './Components/Footer';
import arrowBack from './Assets/img/arrow-back.svg';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      addressError: false,
      cashFlow: null,
      cashFlowAll: null,
      city: null,
      firebaseError: false,
      hoa: null,
      homePayoff: null,
      landscapingService: false,
      loading: false,
      mortgage: null,
      monthlyAppreciation: null,
      poolService: false,
      projectedDepreciationSavings: null,
      projectedImprovements: null,
      projectedMortgageSavings: null,
      projectedRepairs: null,
      projectedVacancy: null,
      saleTotal: null,
      state: null,
      step: 1,
      streetAddress: null,
      totalBenefit: null,
      userId: null,
      yearBuilt: null,
      zestimate: null,
      zestimateError: false,
      zestimateRent: null,
      zillowSearchData: null,
      zip: null,
      zpid: null,
    }

    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleFinanceSubmit = this.handleFinanceSubmit.bind(this);
    this.formReset = this.formReset.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }


  /**
   * Handle Search Submit
  **/
  handleSearchSubmit(event) {
    event.preventDefault();

    let streetAddress = encodeURIComponent(this.state.streetAddress),
        city = encodeURIComponent(this.state.city),
        state = encodeURIComponent(this.state.state),
        zip = encodeURIComponent(this.state.zip);

    this.setState({loading: true}, () => this.handleSearch(streetAddress, city, state, zip) );
  }

  /**
   * Handle Zillow Search
  **/
  handleSearch(streetAddress, city, state, zip) {
    fetch(`https://us-central1-mark-rent-calculator.cloudfunctions.net/zillowSearch/?address=${streetAddress}&citystatezip=${city}+${state}+${zip}`)
    .then( fetchGetStatus )
    .then( response => response.json() )
    .then( data => {

      if( !data || !data.zpid ) {
        this.setState({
          addressError: true,
          loading: false,
          step: 1
        });
      } else {
        this.setState({
          addressError: false,
          loading: false,
          step: 2,
          yearBuilt: data.yearBuilt,
          zillowSearchData: data,
          zpid: data.zpid,
          zestimate: data.zestimate[0].amount[0]._,
          zestimateError: false,
          zestimateRent: data.rentzestimate[0].amount[0]._
        }, () => this.calculateHeight() );
      }
    })
    .catch(error => {
      console.error(error)
      this.setState({
        addressError: true,
        loading: false,
        step: 1
      })
    });
  }

  /**
   *  Results Calculation
  **/
  calculateResults() {
    const rent = this.state.zestimateRent,
          pmFee = calculationValues.pm_fee,
          poolService = calculationValues.pool_service,
          landscapingService = calculationValues.landscaping_service,
          mortgage = this.state.mortgage,
          hoa = this.state.hoa,
          homePayoff = this.state.homePayoff,
          homeEstimate = this.state.zestimate,
          listingFees = calculationValues.listing_fees,
          yearBuilt = this.state.yearBuilt;

    const cashFlow = (rent - pmFee - (this.state.poolService ? poolService : 0) - (this.state.landscapingService ? landscapingService : 0) - mortgage - hoa);
    const saleTotal = (homeEstimate - (homeEstimate*listingFees) - homePayoff);
    const projectedVacancy = (rent*calculationValues.projected_vacancy_loss);
    const projectedRepairs = yearBuilt > calculationValues.old_home_year ? (rent*calculationValues.projected_repairs_new) : (rent*calculationValues.projected_repairs_old);
    const projectedImprovements = yearBuilt > calculationValues.old_home_year ? (rent*calculationValues.projected_improvements_new) : (rent*calculationValues.projected_improvements_old);
    const projectedMortgageSavings = (0.6*mortgage*0.25);
    const projectedDepreciationSavings = (((0.8*homeEstimate)/27.5)*0.25/12);
    const cashFlowAll = (cashFlow - projectedVacancy - projectedRepairs - projectedImprovements + projectedMortgageSavings + projectedDepreciationSavings);
    const monthlyAppreciation = ((homeEstimate * 0.032) / 12 );
    const totalBenefit = (cashFlowAll + monthlyAppreciation);

    this.setState({
      cashFlow,
      cashFlowAll,
      saleTotal,
      monthlyAppreciation,
      totalBenefit,
      projectedDepreciationSavings,
      projectedImprovements,
      projectedMortgageSavings,
      projectedRepairs,
      projectedVacancy,
      loading: false,
      step: 3
    }, () => {
      this.calculateHeight();
      this.sendToFirebase();
    })
  }

  // Handle Input Changes
  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // Handle Input Changes
  handleCheckboxChange = name => event => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  /**
   * Handle Finance Submit
  **/
  handleFinanceSubmit(event) {
    event.preventDefault();
    this.setState({loading: true}, () => this.calculateResults() );
  }

  /**
   * Send Data to Firebase
  **/
  sendToFirebase() {
    const submission = {
      cashFlow: this.state.cashFlow,
      cashFlowAll: this.state.cashFlowAll,
      city: this.state.city,
      date: new Date(),
      hoa: this.state.hoa,
      homePayoff: this.state.homePayoff,
      landscapingService: this.state.landscapingService,
      mortgage: this.state.mortgage,
      monthlyAppreciation: this.state.monthlyAppreciation,
      poolService: this.state.poolService,
      projectedDepreciationSavings: this.state.projectedDepreciationSavings,
      projectedImprovements: this.state.projectedImprovements,
      projectedMortgageSavings: this.state.projectedMortgageSavings,
      projectedRepairs: this.state.projectedRepairs,
      projectedVacancy: this.state.projectedVacancy,
      saleTotal: this.state.saleTotal,
      state: this.state.state,
      streetAddress: this.state.streetAddress,
      totalBenefit: this.state.totalBenefit,
      yearBuilt: this.state.yearBuilt,
      zestimate: this.state.zestimate,
      zestimateRent: this.state.zestimateRent,
      zip: this.state.zip,
      zpid: this.state.zpid,
    }

    // add the submission data to state to easily be referenced later, we used to send to DB but not anymore
    this.setState({
      propertyData: submission
    });
  }

  /**
   * Start over form process
  **/
  formReset() {

    this.setState({
      addressError: false,
      cashFlow: null,
      cashFlowAll: null,
      city: null,
      firebaseError: false,
      hoa: null,
      homePayoff: null,
      landscapingService: false,
      loading: false,
      mortgage: null,
      monthlyAppreciation: null,
      poolService: false,
      projectedDepreciationSavings: null,
      projectedImprovements: null,
      projectedMortgageSavings: null,
      projectedRepairs: null,
      projectedVacancy: null,
      saleTotal: null,
      state: null,
      step: 1,
      streetAddress: null,
      totalBenefit: null,
      yearBuilt: null,
      zestimate: null,
      zestimateError: false,
      zestimateRent: null,
      zillowSearchData: null,
      zip: null,
      zpid: null,
    }, () => {
      document.getElementById('addressForm').reset();
      document.getElementById('financeForm').reset();
    })
  }

  renderBack() {
    return(
      <button
        onClick={this.handleBack}
        style={{
          appearance: `none`,
          border: `none`,
          backgroundColor: `transparent`,
          color: `#f9be00`
        }}
      >
          <img src={arrowBack} style={{width: `15px`, height: `auto`, verticalAlign: `middle`}} alt="" /> Back to Step {(this.state.step - 1)}
      </button>
    )
  }

  handleBack() {
    const currentStep = this.state.step;
    let updatedStep = (currentStep - 1);

    this.setState({
      step: updatedStep
    }, () => this.calculateHeight(true) );
  }

  /**
   * Calculate Height of Step Container
  **/
  calculateHeight(back = false) {
    const step = this.state.step,
          height = document.getElementById(`step-${step}`).offsetHeight,
          stepContainer = document.getElementById('stepContainer');

    const delay = back ? 0 : 250;

    setTimeout(() => {
      stepContainer.style.cssText += `height: ${height}px`;
    }, delay);

  }

  /**
   * Component Did Mount
  **/
  componentDidMount() {
    this.calculateHeight();
  }

  render() {

    const styles = {
      container: {
        display: `flex`,
        flexFlow: `column`,
        justifyContent: `flex-start`,
        alignItems: `center`,
        width: `100%`,
        minHeight: `100vh`,
      },
      stepContainer: {
        position: `relative`,
        width: `100%`,
        minHeight: `60vh`,
        transition: `all 500ms ease-in-out`,
        overflow: `hidden`
      }
    }

    return (
      <Router>
        <main className="App" style={styles.container}>
          <Route exact path="/" component={Header} />
          <Intro />
          <div id="stepContainer" style={styles.stepContainer}>
            <Step1
              handleSearchSubmit={this.handleSearchSubmit}
              handleInputChange={this.handleInputChange}
              handleCheckboxChange={this.handleCheckboxChange}
              loading={this.state.loading}
              className={this.state.step === 1 ? 'card' : 'card card--hidden'}
            />

            <Step2
              handleInputChange={this.handleInputChange}
              handleFinanceSubmit={this.handleFinanceSubmit}
              loading={this.state.loading}
              zillowSearchData={this.state.zillowSearchData}
              className={this.state.step === 2 ? 'card' : this.state.step < 2 ? 'card card--hidden-right' : 'card card--hidden'}
            />

            <Step3
              calculationValues={calculationValues}
              formReset={this.formReset}
              {...this.state}
              className={this.state.step === 3 ? 'card' : this.state.step < 3 ? 'card card--hidden-right' : 'card card--hidden'}
            />
          </div>
          <div style={{marginTop: `20px`, width: `100%`, maxWidth: `600px`}}>
            {this.state.step > 1 ? this.renderBack() : ''}
          </div>
          <AddressError show={this.state.addressError} />
          <DatabaseError show={this.state.firebaseError} />
          <Footer />
        </main>
      </Router>
    );
  }
}

export default App;
