import React, { Component } from 'react';

import FinanceForm from './FinanceForm';

class Step2 extends Component {

  render() {
    return (
      <section id="step-2" className={this.props.className}>
        <h2 style={{textAlign: 'center', marginTop: `0`}}>
          <strong>Step 2</strong>
            <br />
            Property Finances
        </h2>
        <p style={{textAlign: 'center'}}>
          {this.props.zillowSearchData ? this.props.zillowSearchData.address[0].street : ''}
          <br />
          {this.props.zillowSearchData ? this.props.zillowSearchData.address[0].city : ''}, {this.props.zillowSearchData ? this.props.zillowSearchData.address[0].state : ''} {this.props.zillowSearchData ? this.props.zillowSearchData.address[0].zipcode : ''}
        </p>
        <hr />
        <FinanceForm
          handleInputChange={this.props.handleInputChange}
          handleFinanceSubmit={this.props.handleFinanceSubmit}
          loading={this.props.loading}
        />
    </section>
    );
  }

}

export default Step2;
