import React, { Component } from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import loadingSVG from '../Assets/img/loading.svg';

class FinanceForm extends Component {

  render() {

    const styles = {
      label: {
        display: `block`,
        marginBottom: `6px`
      },
      input: {
        marginBottom: `15px`
      },
      toolTip: {
        position: `absolute`,
        top: `0`,
        right: `20px`
      },
      submitContainer: {
        display: `flex`,
        flexFlow: `row nowrap`,
        justifyContent: `flex-end`
      }
    }

    return (
      <div style={{marginTop: `35px`}}>
        <form id="financeForm" onSubmit={this.props.handleFinanceSubmit}>
          <div className="form-group">
            <input
              type="number"
              name="mortgage"
              id="mortgage"
              style={styles.input}
              onChange={this.props.handleInputChange('mortgage')}
              required
            />
            <label style={styles.label}>
              Mortgage (Inc. taxes & insurance - in $)
            </label>
            <span className="input-bar" />
            <div style={styles.toolTip}>
              <OverlayTrigger
                overlay={<Tooltip id="mortgageToolTip">If you have no mortgage, estimate the monthly tax and insurance payment here.</Tooltip>}
                placement="top"
              >
                <i className="fa fa-question-circle" aria-hidden="true" />
              </OverlayTrigger>
            </div>
          </div>

          <div className="form-group">
            <input
              type="number"
              name="hoa"
              id="hoa"
              style={styles.input}
              onChange={this.props.handleInputChange('hoa')}
              required
            />
            <label style={styles.label}>
              HOA Fees (in $)
            </label>
            <span className="input-bar" /><div style={styles.toolTip}>
              <OverlayTrigger
                overlay={<Tooltip id="hoaToolTip">Input the monthly HOA fee here.</Tooltip>}
                placement="top"
              >
                <i className="fa fa-question-circle" aria-hidden="true" />
              </OverlayTrigger>
            </div>
          </div>

          <div className="form-group">
            <input
              type="number"
              name="homePayoff"
              id="homePayoff"
              style={styles.input}
              onChange={this.props.handleInputChange('homePayoff')}
              required
            />
            <label style={styles.label}>
              Estimated home payoff (in $)
            </label>
            <span className="input-bar" />
              <div style={styles.toolTip}>
                <OverlayTrigger
                  overlay={<Tooltip id="payoffToolTip">Input the principal amount you still owe on your mortgage. Estimate if you need to.</Tooltip>}
                  placement="top"
                >
                  <i className="fa fa-question-circle" aria-hidden="true" />
                </OverlayTrigger>
              </div>
          </div>

          <div style={styles.submitContainer}>
            {this.props.loading ? <img src={loadingSVG} alt="Loading..." style={{width: `40px`, height: `40px`}} /> : <input type="submit" value="Next" /> }
          </div>

        </form>
      </div>
    );
  }

}

export default FinanceForm;
