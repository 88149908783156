import React, { Component } from 'react';
import states from '../states.json';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import loadingSVG from '../Assets/img/loading.svg';

class AddressForm extends Component {

  // handle making options with states
  handleUSStates() {
    let options = [];

    for ( const [i, stateUS] of Object.entries(states) ) {
      options.push(<option key={i} value={stateUS}>{stateUS}</option>)
    }

    return options;
  }

  render() {

    const styles = {
      label: {
        display: `block`,
        marginBottom: `6px`
      },
      input: {
        marginBottom: `15px`
      },
      submitContainer: {
        display: `flex`,
        flexFlow: `row nowrap`,
        justifyContent: `flex-end`
      },
      tooltip: {
        borderBottom: `1px dashed #808083`
      },
      tooltipIcon: {
        marginLeft: `5px`
      }
    }

    return (
      <div style={{marginTop: `35px`}}>
        <form id="addressForm" onSubmit={this.props.handleSearchSubmit}>
          <div className="form-group">
            <input
              id="streetAddress"
              name="streetAddress"
              type="text"
              style={styles.input}
              onChange={this.props.handleInputChange('streetAddress')}
              required
            />
            <label style={styles.label}>
              Street Address
            </label>
            <span className="input-bar"></span>
          </div>

          <div className="form-group">
            <input
              id="city"
              name="city"
              type="text"
              style={styles.input}
              onChange={this.props.handleInputChange('city')}
              required
            />
            <label style={styles.label}>
              City
            </label>
            <span className="input-bar"></span>
          </div>

          <div className="form-row">
            <div className="form-group form-group--select">
              <select
                id="state"
                onChange={this.props.handleInputChange('state')}
                required
              >
                <option value="" selected disabled hidden>
                  Select A State
                </option>
                {this.handleUSStates()}
              </select>
            </div>

            <div className="form-group form-group--zip">
              <input
                id="zip"
                name="zip"
                type="text"
                pattern="[0-9]{5}"
                style={styles.input}
                onChange={this.props.handleInputChange('zip')}
                required
              />
              <label style={styles.label}>
                ZIP Code
              </label>
              <span className="input-bar"></span>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group form-group--checkbox">
              <input
                id="poolService"
                name="poolService"
                type="checkbox"
                onChange={this.props.handleCheckboxChange('poolService')}
              />
              <label htmlFor="poolService">
                Has Pool
              </label>
            </div>
            <div className="form-group form-group--checkbox">
              <input
                id="landscaping"
                name="landscaping"
                type="checkbox"
                onChange={this.props.handleCheckboxChange('landscapingService')}
              />
              <label htmlFor="landscaping">
                Requires Landscape Maintainence
                <OverlayTrigger
                  overlay={<Tooltip id="landcapingToolTip">Check this box if the property has grass and/or other landscaping that needs regular care above what a tenant is likely to provide</Tooltip>}
                  placement="top"
                >
                  <i className="fa fa-info-circle" style={styles.tooltipIcon} />
                </OverlayTrigger>
              </label>
            </div>
          </div>

          <div style={styles.submitContainer}>
            {this.props.loading ? <img src={loadingSVG} alt="Loading..." style={{width: `40px`, height: `40px`}} /> : <input type="submit" value="Next" /> }
          </div>
        </form>
      </div>
    );
  }
}

export default AddressForm;
