import React, { Component } from 'react';

class Intro extends Component {

  render() {
    const styles = {
      intro: {
        width: `50%`,
        padding: `50px 0`,
        textAlign: `center`,
      },
      h1: {
        color: `#fff`
      },
      p: {
        color: `#fff`
      }
    }
    return (
      <div className="intro" style={styles.intro}>
        <h1 style={styles.h1}>Rent vs. Sell Calculator</h1>
        <p style={styles.p}>This calculator helps with the decision to sell or rent your property by showing your expected monthly cash flow and expected total monthly benefit for renting the property and compares this to your expected net proceeds if you were to sell the property</p>
      </div>
    );
  }

}

export default Intro;
