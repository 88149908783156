import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import ContactForm from './ContactForm';

class ContactModal extends Component {

  render() {
    return (
      <Modal show={this.props.showModal} onHide={this.props.hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Discuss Your Options With Our Team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter you details and we will be in touch about your property.</p>
          <ContactForm propertyData={this.props.propertyData} hideModal={this.props.hideModal} />
        </Modal.Body>
      </Modal>
    );
  }

}

export default ContactModal;
