import React, { Component } from 'react';

class Footer extends Component {

  render() {

    const styles = {
      footer: {
        display: `block`,
        position: `relative`,
        bottom: `0`,
        margin: `120px auto 0`,
        width: `100%`,
        backgroundColor: 'white',
        textAlign: 'center'
      }
    }
    return (
      <div style={styles.footer}>
        <p>
          &copy; {new Date().getFullYear()} Mark Brower Properties<br />
          &copy; Zillow, Inc., 2006-2018. Use is subject to <a href="https://www.zillow.com/corp/Terms.htm">Terms of Use</a>
        </p>
        <a href="https://zillow.com">
          <img src="https://www.zillow.com/widgets/GetVersionedResource.htm?path=/static/logos/Zillowlogo_200x50.gif" alt="Provided By Zillow" />
        </a>
      </div>
    );
  }

}

export default Footer;
