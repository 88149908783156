import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

import '../Assets/css/bootstrap.css';

class AddressError extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      show: this.props.show
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if( nextProps.show === this.state.show ) {
      return false
    } else {
      this.setState({
        show: nextProps.show
      });
      return true
    }
  }

  render() {
    const styles = {
      box: {
        position: `fixed`,
        top: '20px'
      }
    }
    if (this.state.show) {
      return (
        <Alert bsStyle="danger" style={styles.box}>
          <h4>Address Not Found</h4>
          <p>
            Sorry, but it seems we can't find that address or it is invalid. <br /> Please try again, or a different address.
          </p>
        </Alert>
      );
    }
    return <div style={{display: `none`}} />
  }

}

export default AddressError;
