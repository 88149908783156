import React, { Component } from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import ContactModal from './ContactModal';

class Step3 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    }

    this.hideModal = this.hideModal.bind(this);
  }

  hideModal() {
    this.setState({showModal: false});
  }

  showModal(e) {
    e.preventDefault();
    this.setState({showModal: true});
  }

  render() {

    const styles = {
      positive: {
        color: `rgb(54, 133, 72)`
      },
      negative: {
        color: `rgb(199, 39, 39)`
      },
      resultsRow: {
        display: `flex`,
        position: `relative`,
        margin: `0 0 8px`,
        flexFlow: `row nowrap`,
        justifyContent: `space-between`,
        alignItems: `flex-end`,
        width: `100%`
      },
      tooltip: {
        borderBottom: `1px dashed #808083`
      },
      tooltipIcon: {
        marginLeft: `5px`
      }
    }

    // number formatting
    const nf = Intl.NumberFormat();

    return (
      <section id="step-3" className={this.props.className}>
        <h2 style={{textAlign: 'center', marginTop: `0`}}>
          <strong>Results</strong>
        </h2>
        <p style={{textAlign: 'center'}}>
          {this.props.zillowSearchData ? this.props.zillowSearchData.address[0].street : ''}
          <br />
          {this.props.zillowSearchData ? this.props.zillowSearchData.address[0].city : ''}, {this.props.zillowSearchData ? this.props.zillowSearchData.address[0].state : ''} {this.props.zillowSearchData ? this.props.zillowSearchData.address[0].zipcode : ''}
        </p>
        <hr />

        <h2>
          Leasing Your Property
        </h2>

        <div style={styles.resultsRow}>
          <div>
            <strong>Monthly Rent Zestimate:</strong>
          </div>
          <div style={{textAlign: `right`}}>
            <OverlayTrigger
              overlay={<Tooltip id="Rent Zestimate">Rent Zestimate from Zillow. For a more precise estimate, contact us.</Tooltip>}
              placement="top"
            >
              <span style={styles.tooltip}>${nf.format(this.props.zestimateRent)}</span>
            </OverlayTrigger>
          </div>
        </div>
        <div style={styles.resultsRow}>
          <div>
            <strong>Mortgage (including taxes & insurance):</strong>
          </div>
          <div style={{textAlign: `right`}}>
            (${nf.format(this.props.mortgage)})
          </div>
        </div>
        <div style={styles.resultsRow}>
          <div>
            <strong>Property Management Fee:</strong>
          </div>
          <div style={{textAlign: `right`}}>
            <span>(${this.props.calculationValues.pm_fee ? this.props.calculationValues.pm_fee : ''})</span>
            <OverlayTrigger
              overlay={<Tooltip id="pmToolTip">MBP delivers a full business solution with our "Conflict Free Property Management" guarantee.</Tooltip>}
              placement="top"
            >
              <i className="fa fa-info-circle" style={styles.tooltipIcon} />
            </OverlayTrigger>
          </div>
        </div>
        <div style={styles.resultsRow}>
          <div>
            <strong>Pool Service:</strong>
          </div>
          <div style={{textAlign: `right`}}>
            (${this.props.poolService ? this.props.calculationValues.pool_service : '0'})
          </div>
        </div>
        <div style={styles.resultsRow}>
          <div>
            <strong>Landscaping Service:</strong>
          </div>
          <div style={{textAlign: `right`}}>
            (${this.props.landscapingService ? this.props.calculationValues.landscaping_service : '0'})
          </div>
        </div>
        <div style={styles.resultsRow}>
          <div>
            <strong>HOA Fee:</strong>
          </div>
          <div style={{textAlign: `right`}}>
            (${this.props.hoa})
          </div>
        </div>
        <div style={styles.resultsRow}>
          <div>
            <h3 style={{marginBottom: `0`}}>
              <strong>Monthly Cash Flow Before Repairs Cap Ex & Tax Benefits:</strong>
            </h3>
          </div>
          <div style={{textAlign: `right`}}>
            <h3 style={{marginBottom: `0`}}>
              <span style={this.props.cashFlow > 0 ? styles.positive : styles.negative}>${this.props.cashFlow ? nf.format(this.props.cashFlow.toFixed(0)) : ''}</span>
            </h3>
          </div>
        </div>

        <div style={styles.resultsRow}>
          <div>
            <strong>Projected Vacancy Loss:</strong>
          </div>
          <div style={{textAlign: `right`}}>
            <span>
              (${this.props.projectedVacancy ? this.props.projectedVacancy.toFixed(0) : ''})
            </span>
            <OverlayTrigger
              overlay={<Tooltip id="vacancyTooltip">We are expecting 5% vacancy, this number is the monthly lack of revenue (cost) for a projected 5% vacancy rate.</Tooltip>}
              placement="top"
            >
              <i className="fa fa-info-circle" style={styles.tooltipIcon} />
            </OverlayTrigger>
          </div>
        </div>
        <div style={styles.resultsRow}>
          <div>
            <strong>Projected Repair Expenses:</strong>
          </div>
          <div style={{textAlign: `right`}}>
            <span>
              (${this.props.projectedRepairs ? this.props.projectedRepairs.toFixed(0) : ''})
            </span>
            <OverlayTrigger
              overlay={<Tooltip id="repairsTooltip">Approximate cost of 'normal wear and tear' repairs that a landlord is expected to make. This figure assumes the property is in good condition with no deffered maintenance.</Tooltip>}
              placement="top"
            >
              <i className="fa fa-info-circle" style={styles.tooltipIcon} />
            </OverlayTrigger>
          </div>
        </div>
        <div style={styles.resultsRow}>
          <div>
            <strong>Projected Cap Improvements Cost:</strong>
          </div>
          <div style={{textAlign: `right`}}>
            <span>
              (${this.props.projectedImprovements ? this.props.projectedImprovements.toFixed(0) : ''})
            </span>
            <OverlayTrigger
              overlay={<Tooltip id="capTooltip">Capital improvements are things like roof replacement, AC replacement, etc. They are generally more expensive, periodic costs.</Tooltip>}
              placement="top"
            >
              <i className="fa fa-info-circle" style={styles.tooltipIcon} />
            </OverlayTrigger>
          </div>
        </div>
        <div style={styles.resultsRow}>
          <div style={{marginTop: `15px`}}>
            <strong>Projected Mortgage Interest Tax Savings:</strong>
          </div>
          <div style={{textAlign: `right`}}>
            <span>${this.props.projectedMortgageSavings ? this.props.projectedMortgageSavings.toFixed(0) : ''}</span>
            <OverlayTrigger
              overlay={<Tooltip id="mortgageSavingsTooltip">Assumes you write off mortgage interest paid and your marginal tax bracket is 25%.</Tooltip>}
              placement="top"
            >
              <i className="fa fa-info-circle" style={styles.tooltipIcon} />
            </OverlayTrigger>
          </div>
        </div>
        <div style={styles.resultsRow}>
          <div>
            <strong>Projected Depreciation Tax Savings:</strong>
          </div>
          <div style={{textAlign: `right`}}>
            <span>${this.props.projectedDepreciationSavings ? this.props.projectedDepreciationSavings.toFixed(0) : ''}</span>
            <OverlayTrigger
              overlay={<Tooltip id="depreciationTooltip">Assumes you write off depreciation on the improvements estimated at 80% of the total value of the property over a straight-line 27.5 year schedule</Tooltip>}
              placement="top"
            >
              <i className="fa fa-info-circle" style={styles.tooltipIcon} />
            </OverlayTrigger>
          </div>
        </div>
        <div style={styles.resultsRow}>
          <div>
            <h3>
              <strong>Estimated Monthly Cash Flow Total:</strong>
            </h3>
          </div>
          <div style={{textAlign: `right`}}>
            <h3>
              <span style={this.props.cashFlowAll > 0 ? styles.positive : styles.negative}>${this.props.cashFlowAll ? nf.format(this.props.cashFlowAll.toFixed(0)) : ''}</span>
            </h3>
          </div>
        </div>

        <div style={styles.resultsRow}>
          <div>
            <h3 style={{marginTop: `0`}}>
              <strong>Estimated Monthly Home Value Appreciation:</strong>
            </h3>
          </div>
          <div style={{textAlign: `right`}}>
            <h3 style={{marginTop: `0`}}>
              <span style={this.props.monthlyAppreciation > 0 ? styles.positive : styles.negative}>${this.props.monthlyAppreciation ? nf.format(this.props.monthlyAppreciation.toFixed(0)) : ''}</span>
              <OverlayTrigger
                overlay={<Tooltip id="appreciationTooltip">Based on historical 3.2% appreciation rate</Tooltip>}
                placement="top"
              >
                <i className="fa fa-info-circle" style={styles.tooltipIcon} />
              </OverlayTrigger>
            </h3>
          </div>
        </div>

        <div style={styles.resultsRow}>
          <div>
            <h3 style={{marginTop: `0`}}>
              <strong>Estimated Monthly "Total Benefit" for Leasing Home:</strong>
            </h3>
          </div>
          <div style={{textAlign: `right`}}>
            <h3 style={{marginTop: `0`}}>
              <span style={this.props.totalBenefit > 0 ? styles.positive : styles.negative}>${this.props.totalBenefit ? nf.format(this.props.totalBenefit.toFixed(0)) : ''}</span>
              <OverlayTrigger
                overlay={<Tooltip id="totalBenefitTooltip">Total of Estimated Monthly Cash Flow and Estimated Home Value Appreciation</Tooltip>}
                placement="top"
              >
                <i className="fa fa-info-circle" style={styles.tooltipIcon} />
              </OverlayTrigger>
            </h3>
          </div>
        </div>

        <h2>
          Selling Your Property
        </h2>

        <div style={styles.resultsRow}>
          <div>
            <strong>Home Value Zestimate:</strong>
          </div>
          <div style={{textAlign: `right`}}>
            <OverlayTrigger
              overlay={<Tooltip id="Zestimate">Zestimate from Zillow</Tooltip>}
              placement="top"
            >
              <span style={styles.tooltip}>${nf.format(this.props.zestimate)}</span>
            </OverlayTrigger>
          </div>
        </div>
        <div style={styles.resultsRow}>
          <div>
            <strong>Loan Payoff:</strong>
          </div>
          <div style={{textAlign: `right`}}>
            (${nf.format(this.props.homePayoff)})
          </div>
        </div>
        <div style={styles.resultsRow}>
          <div>
            <h3>
              <strong>Estimated Net Sale Proceeds:</strong>
            </h3>
          </div>
          <div style={{textAlign: `right`}}>
            <h3>
              <span style={this.props.saleTotal > 0 ? styles.positive : styles.negative}>${this.props.saleTotal ? nf.format(this.props.saleTotal.toFixed(0)) : '' } &nbsp; </span>
                <OverlayTrigger
                  overlay={<Tooltip id="netSaleTooltip">Net of our discounted listing commissions, estimated title and closing fees, pro-rated taxes, and your loan payoff.</Tooltip>}
                  placement="top"
                >
                  <i className="fa fa-question-circle"></i>
                </OverlayTrigger>
            </h3>
          </div>
        </div>

        <div>
          <button onClick={ (e) => this.showModal(e) } className="button">Discuss Your Options With Our Team</button>
          <p
            onClick={this.props.formReset}
            style={{textAlign: `center`, textDecoration: `underline`, cursor: `pointer`, color: `#004d71`}}>
            start over
          </p>
          <hr />
          <p style={{fontSize: `14px`, textAlign: 'center'}}>
            <a href={this.props.zillowSearchData ? this.props.zillowSearchData.links[0].homedetails : ''}>See more details for {this.props.zillowSearchData ? this.props.zillowSearchData.address[0].city : ''}, {this.props.zillowSearchData ? this.props.zillowSearchData.address[0].state : ''} {this.props.zillowSearchData ? this.props.zillowSearchData.address[0].zipcode : ''}  on Zillow</a>
          </p>
          <hr />
          <p style={{fontSize: `14px`}}>
            This information is provided for illustrative purposes only. Mark Brower Properties makes no representations or warranties about the accuracy of this information. Consult tax and legal advice from professionals licensed to provide such advice to determine whether this illustration fits your situation.
          </p>
        </div>

        <ContactModal showModal={this.state.showModal} hideModal={this.hideModal} propertyData={this.props.propertyData} />

      </section>
    );
  }

}

export default Step3;
