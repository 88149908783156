import React, { Component } from 'react';

import AddressForm from './AddressForm';

class Step1 extends Component {

  render() {
    return (
      <section id="step-1" className={this.props.className}>
        <h2 style={{textAlign: 'center', marginTop: `0`}}>
          <strong>Step 1</strong>
            <br />
            Tell us about your property
        </h2>
        <hr />
        <AddressForm
          handleSearchSubmit={this.props.handleSearchSubmit}
          handleInputChange={this.props.handleInputChange}
          handleCheckboxChange={this.props.handleCheckboxChange}
          loading={this.props.loading}
        />
      </section>
    );
  }

}

export default Step1;
