import React, { Component } from 'react';

class ContactForm extends Component {

  constructor(props){
    super(props);

    this.state = {
      propertyData: this.props.propertyData ? this.props.propertyData : null,
      userName: null,
      userEmail: null,
      submitted: false
    }

    this.sendToFirebase = this.sendToFirebase.bind(this);
  }

  // Handle Input Changes
  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  /**
  * Send Data to Firebase for Email Notification
 **/
 sendToFirebase(event) {
   // prevent form from submitting
   event.preventDefault();

   let submission = {
     userName: this.state.userName,
     userEmail: this.state.userEmail,
     propertyData: this.state.propertyData
   }

   console.log(submission);

   fetch('https://us-central1-mark-rent-calculator.cloudfunctions.net/zillowSearch/submit', {
     body: JSON.stringify(submission),
     headers: new Headers({
         'Content-Type': 'application/json'
       }),
     method: 'POST',
     mode: 'no-cors',
     referrer: 'no-referrer'
   })
   .then( response => {
     if( response.type === 'opaque' ) {
       return this.setState({submitted: true});
     } else {
       return console.log(response);
     }
   })
   .catch(error => {
     console.error(error)
     return this.setState({submitted: 'error'});
   });

 }


  render() {

    const styles = {
      label: {
        display: `block`,
        marginBottom: `6px`
      },
      input: {
        marginBottom: `15px`
      },
      submitContainer: {
        display: `flex`,
        flexFlow: `row nowrap`,
        justifyContent: `flex-end`
      }
    }

    if( this.state.submitted ) {
      return (
        <div style={{marginTop: `35px`}}>
          <h3>
            Thank you! We will be in touch shortly.
          </h3>
        </div>
      )
    }

    if( this.state.submitted === 'error' ) {
      return(
        <div style={{marginTop: `35px`}}>
          <h3>
            Sorry, there was an error processing your request.
          </h3>
        </div>
      )
    }

    return (
      <div style={{marginTop: `35px`}}>
        <form onSubmit={this.sendToFirebase}>
          <div className="form-group">
            <input
              id="userName"
              name="userName"
              type="text"
              style={styles.input}
              onChange={this.handleInputChange('userName')}
              required
            />
            <label style={styles.label}>
              Name
            </label>
            <span className="input-bar"></span>
          </div>
          <div className="form-group">
            <input
              id="userEmail"
              name="userEmail"
              type="email"
              style={styles.input}
              onChange={this.handleInputChange('userEmail')}
              required
            />
            <label style={styles.label}>
              Email
            </label>
            <span className="input-bar"></span>
          </div>
          <div style={styles.submitContainer}>
            <input type="submit" value="Submit" />
          </div>
        </form>
      </div>
    );
  }

}

export default ContactForm;
